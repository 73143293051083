<template>
  <div v-loading="loaders.surgeryCategories" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>

    <div class="d-flex justify-content-between align-items-center align-items-center">
      <h1 class="m-0">List of Surgery Categories</h1>
      <div class="d-flex justify-content-end">
          <base-button class="btn-secondary-action title-btn-padding button-font" @click="toggleModal('Save')">Add New</base-button>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.surgeryCategories"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.surgeryCategories"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.surgeryCategories"
        >Deactivate
        </base-button>
      </div>
        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          width="100%"
          ref="multipleSelectionTable"
          :data="response.surgeryCategories"
          @selection-change="surgeryCategoriesMultiSelect"
        >
          <el-table-column prop="selected" type="selection" min-width="130px">
          </el-table-column>
          <el-table-column label="Name" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" prop="status" min-width="130px">
            <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS">
            <div slot-scope="{ $index, row }" class="d-flex">

              <img
                class="pointer"
                @click="toggleModal('Update' , row)"
                src="/img/icons/buttons/edit.svg"
                alt="Edit"
              />
              <img
                class="pointer ml-2"
                @click="handleDelete($index, row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </div>
          </el-table-column>
        </el-table>
      <div class="col-12 d-flex justify-content-end flex-wrap">
        <custom-pagination
          class="mt-4 pagination-no-border float-right"
          v-model="request.pagination.page"
          :per-page="request.pagination.perPage"
          :total="request.pagination.total"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeSurgeryCategory"
        @onRemoveMultiple="deleteSurgeryCategories"
      />
      <el-dialog
        :title="modal.title"
        :visible.sync="modals.SurgeryCategoryModal"
        width="35%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <validation-observer v-slot="{handleSubmit}" ref="formSurgeryCategoryValidator">
          <form role="form" ref="formSurgeryCategory" :name="modal.formType" @submit.prevent="handleSubmit(onFormSubmit)">
            <div class="mt--4">
              <base-input
                class=""
                :rules="{required: true}"
                name="Category Name"
                label="Category Name*" v-model="surgeryCategory.name"
                placeholder="Category Name">
              </base-input>

              <base-input label="Status">
                <el-select
                  class="w-100"
                  label="Status"
                  v-model="surgeryCategory.status">
                  <el-option
                    v-for="option in dropdowns.status"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="text-right mt-4">
              <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
              <base-button class="btn-primary-action pl-5 pr-5 br-4" native-type="submit" type="success">{{modal.buttonText}}</base-button>
            </div>
          </form>
        </validation-observer>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import BackButton from "@/components/Router/BackButton";
import CustomPagination from "../../../../Components/Pagination/CustomPagination";

const RemoveModal = () => import(/* webpackChunkName: "surgeryCategoriesRemoveModal" */ "@/components/Modals/RemoveModal.vue");

export default {
  name: "SurgeryCategories",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,BackButton, CustomPagination
  },
  mounted() {
    this.fetchSurgeryCategories()
  },
  data() {
    return {
      loaders: {
        surgeryCategories: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      modals : {
        SurgeryCategoryModal : false,
      },
      modal : {
        title : 'Add Surgery Category',
        buttonText : 'Save',
        formType: 'Save',
      },
      surgeryCategory : {
        id : '',
        name : '',
        status : 1
      },
      selectedSurgeryCategory : '',
      dropdowns: {
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        surgeryCategories: [],
      },
      request : {
        pagination : {
          page:1,
          currentPage:1,
          perPage:20,
          total:1,
        }
      },
    };
  },
  methods: {

    fetchSurgeryCategories() {
      let vm = this;
      vm.loaders.surgeryCategories = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/surgery-categories?page='+vm.request.pagination.page)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.surgeryCategories = response.data.data.data;
          vm.request.pagination.page = response.data.data.current_page;
          vm.request.pagination.perPage = response.data.data.per_page;
          vm.request.pagination.total = response.data.data.total;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.surgeryCategories = false);
    },

    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    surgeryCategoriesMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    clearSelection() {
      this.$refs.multipleSelectionTable.clearSelection();
    },

    statusUpdateRequest(ids, status) {
      this.loaders.surgeryCategories = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/surgery-categories/toggle-status`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchSurgeryCategories()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.surgeryCategories = false
        })
    },

    handleDelete(index, row) {
      this.promptRemove(row)
    },

    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Surgery Category.\n' +
        'Deleting Sub Category will remove all Non Surgical Weight Loss info associated with it.';
      this.removeId = row.id;
    },

    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these Surgery Categories.\n' +
        'Deleting Sub Category will remove all Non Surgical Weight Loss info associated with it.';
      this.removeId = this.extractSelectedIds;
    },

    removeSurgeryCategory(id) {
      this.deleteRequest(id)
    },

    deleteSurgeryCategories() {
      this.deleteRequest(this.removeId)
    },

    deleteRequest(data) {
      this.loaders.surgeryCategories = true
      this.loaders.checkboxButton.Delete = true
      const payload = { ids: Array.isArray(data) ? data : [data] };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/surgery-categories/destroy`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = [];
          this.fetchSurgeryCategories();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.checkboxButton.Delete = false;
          this.loaders.surgeryCategories = false;
        })
    },

    toggleModal(modalType ,row){
      if(modalType==='Save'){
        this.modal.title = 'Add Surgery Category';
        this.modal.buttonText = 'Save';
        this.modal.formType = 'Save';
      } else if(modalType==='Update'){
        this.modal.title = 'Update Surgery Category';
        this.modal.buttonText = 'Update';
        this.modal.formType = 'Update';
        this.selectedSurgeryCategory = row.id;
        this.fetchSurgeryCategory();
      }
      this.modals.SurgeryCategoryModal = !this.modals.SurgeryCategoryModal;
    },

    fetchSurgeryCategory() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/content/surgery-categories/' + this.selectedSurgeryCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.surgeryCategory.id = response.data.data.id;
          vm.surgeryCategory.name = response.data.data.name;
          vm.surgeryCategory.status = response.data.data.status;
        })
        .catch((error) => console.error(error.response));
    },

    async onFormSubmit(formName){
      const isSurgeryCategoryFormValid = await this.$refs['formSurgeryCategoryValidator'].validate();

      if (isSurgeryCategoryFormValid) {
        if (this.$refs.formSurgeryCategory.name === 'Save') {
          this.storeSurgeryCategory();
        } else if (this.$refs.formSurgeryCategory.name === 'Update') {
          this.updateSurgeryCategory();
        } else {
          console.log('Hello Else World');
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    storeSurgeryCategory() {
      let vm = this;
      vm.loaders.surgeryCategories = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/content/surgery-categories/store`, vm.surgeryCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.handleClose();
          this.fetchSurgeryCategories();
          this.modals.SurgeryCategoryModal = false;
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.surgeryCategories = false
        })
    },

    updateSurgeryCategory() {
      let vm = this;
      vm.loaders.surgeryCategories = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/content/surgery-categories/update`, vm.surgeryCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.handleClose();
          this.fetchSurgeryCategories();
          this.modals.SurgeryCategoryModal = false;
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.surgeryCategories = false
        })
    },

    handleClose(){
      this.clearSurgeryCategoryForm();
      this.$refs.formSurgeryCategoryValidator.reset();
      this.modals.SurgeryCategoryModal = false;
    },

    clearSurgeryCategoryForm() {
      this.surgeryCategory.id = '';
      this.surgeryCategory.name = '';
      this.surgeryCategory.status = 1;
    },
    changePage(page){
      if(page != this.request.pagination.page ){
        this.request.pagination.page = page;
        this.fetchSurgeryCategories();
      }
    },
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>
.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>
