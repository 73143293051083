<template>
  <div v-loading="loaders.contentSubCategories" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of {{ menu.singularTitle }} Sub Categories</h1>
      <div class="d-flex justify-content-end">
        <base-button class="btn-secondary-action title-btn-padding button-font" @click="toggleModal('Save')">Add New</base-button>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.contentSubCategories"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.contentSubCategories"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.contentSubCategories"
        >Deactivate
        </base-button>
      </div>
        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          width="100%"
          ref="multipleSelectionTable"
          :data="response.contentSubCategories"
          @selection-change="contentSubCategoriesMultiSelect"
        >
          <el-table-column prop="selected" type="selection" min-width="130px">
          </el-table-column>
          <el-table-column label="Sub Category" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="ellipsis-overflow-text">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Category" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{
                  (scope.row[menu.sctcRelation]) ? scope.row[menu.sctcRelation].name : ''
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" prop="status" min-width="130px">
            <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS" width="100px">
            <div slot-scope="{ $index, row }" class="d-flex">
              <img
                class="pointer"
                @click="toggleModal('Update' , row)"
                src="/img/icons/buttons/edit.svg"
                alt="Edit"
              />
              <img
                class="pointer ml-2"
                @click="handleDelete(row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </div>
          </el-table-column>
        </el-table>
      <div class="col-12 d-flex justify-content-end flex-wrap">
        <custom-pagination
          class="mt-4 pagination-no-border float-right"
          v-model="request.pagination.page"
          :per-page="request.pagination.perPage"
          :total="request.pagination.total"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeContentSubCategory"
        @onRemoveMultiple="deleteContentSubCategories"
      />
      <el-dialog
        :title="modal.title"
        :visible.sync="modals.ContentSubCategoryModal"
        width="35%"
        :before-close="handleClose"
        :close-on-click-modal="false">
          <el-form role="form" :model="contentSubCategory" ref="formContentSubCategory" :name="modal.formType" >
            <div class="mt--4">
              <label class="form-control-label" for="">Select {{ menu.singularTitle }} Category*</label>
              <el-form-item prop="contentCategory" :rules="rules.contentCategory">
                <el-select
                  class="w-100"
                  label="Category"
                  :rules="{required: true}"
                  v-model="contentSubCategory.contentCategory">
                  <el-option
                    v-for="option in response.contentCategories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <label class="form-control-label" for="">{{ menu.singularTitle }} Sub Category Name*</label>
              <el-form-item prop="name" :rules="rules.name">
                <el-input
                  class=""
                  name="Category Name"
                  v-model="contentSubCategory.name"
                  placeholder="Sub Category Name">
                </el-input>
              </el-form-item>
              <label for="">Status*</label>
              <el-form-item prop="status" :rules="rules.status">
                <el-select
                  class="w-100"
                  v-model="contentSubCategory.status">
                  <el-option
                    v-for="option in dropdowns.status"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="text-right mt-4">
              <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
              <base-button class="btn-primary-action pl-5 pr-5 br-4" @click="onFormSubmit('formContentSubCategory')" type="success">{{modal.buttonText}}</base-button>
            </div>
          </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import BackButton from "@/components/Router/BackButton";
import CustomPagination from "../../../Components/Pagination/CustomPagination";
import menuConstants from "@/constants/contentMenuConstants";

const RemoveModal = () => import(/* webpackChunkName: "contentSubCategoriesRemoveModal" */ "@/components/Modals/RemoveModal.vue");

export default {
  name: "ContentSubCategories",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal, BackButton, CustomPagination
  },
  mounted() {
    this.fetchContentSubCategories()
  },
  data() {
    return {
      menus: this.$store.getters['contentCategory/getMenuItem'],
      loaders: {
        contentSubCategories: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        contentCategories: [],
        contentSubCategories: [],
      },
      modals: {
        ContentSubCategoryModal: false,
      },
      modal: {
        title: 'Add Content Sub Category',
        buttonText: 'Save',
        formType: 'Save',
      },
      contentSubCategory: {
        id: '',
        name: '',
        status: 1,
        contentCategory: '',
      },
      selectedContentSubCategory: '',
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      rules: {
        name: [
          {required: true, message: 'Please input Sub Category Name', trigger: 'blur'},
          {min: 3, max: 55, message: 'Length should be 3 to 55', trigger: 'blur'}
        ],
        status: [
          {required: true, message: 'Please select status', trigger: 'change'},
        ],
        contentCategory: [
          {required: true, message: 'Please select Category', trigger: 'change'},
        ],
      },
      request: {
        pagination: {
          page: 1,
          currentPage: 1,
          perPage: 20,
          total: 1,
        }
      },
    };
  },
  methods: {
    fetchContentSubCategories() {
      let vm = this;
      vm.loaders.contentSubCategories = true

      let payLoad = {
        page: vm.request.pagination.page,
        menuId: vm.menu.id,
      }
      vm.$store.dispatch('contentSubCategory/_APIIndex', payLoad).then(response => {
        vm.response.contentSubCategories = response.data.data.data;
        vm.request.pagination.page = response.data.data.current_page;
        vm.request.pagination.perPage = response.data.data.per_page;
        vm.request.pagination.total = response.data.data.total;
      }).catch(error => {console.error(error.response);
      }).finally(() => vm.loaders.contentSubCategories = false);

    },

    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    contentSubCategoriesMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    clearSelection() {
      this.$refs.multipleSelectionTable.clearSelection();
    },

    statusUpdateRequest(ids, status) {
      let vm = this;
      vm.loaders.contentSubCategories = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
        menuId: this.menu.id
      };

      vm.$store.dispatch('contentSubCategory/_APIToggleStatus', payload).then(response => {
        vm.removeId = []
        vm.fetchContentSubCategories()
      }).catch(error => {
        console.error(error.response);
      }).finally(() => vm.loaders.contentSubCategories = false);
    },

    handleDelete(row) {
      this.promptRemove(row)
    },

    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = `You want to remove this ${this.menu.singularTitle} Sub-Category.\n` +
        `Deleting Sub-Category will remove all ${this.menu.pluralTitle} associated with it.`;
      this.removeId = row.id;
    },

    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = `You want to remove these ${this.menu.singularTitle} Sub-Categories.\n` +
        `Deleting Sub-Categories will remove all ${this.menu.pluralTitle} associated with it.`;
      this.removeId = this.extractSelectedIds;
    },

    removeContentSubCategory(id) {
      this.deleteRequest(id)
    },

    deleteContentSubCategories() {
      this.deleteRequest(this.removeId)
    },

    deleteRequest(data) {
      let vm  = this;
      vm.loaders.contentSubCategories = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data], menuId: this.menu.id};

      vm.$store.dispatch('contentSubCategory/_APIDelete', payload).then(response => {
        vm.removeId = [];
        vm.fetchContentSubCategories();
        vm.$store.commit("hideRemoveModal");
      }).catch(error => {console.error(error.response);
      }).finally(() =>{
        vm.loaders.checkboxButton.Delete = false;
        vm.loaders.contentSubCategories = false;
      } );
    },

    toggleModal(modalType, row) {
      if (modalType === 'Save') {
        this.modal.title = `Add ${this.menu.singularTitle} Sub Category`;
        this.modal.buttonText = 'Save';
        this.modal.formType = 'Save';
      } else if (modalType === 'Update') {
        this.modal.title = `Update ${this.menu.singularTitle} Sub Category`;
        this.modal.buttonText = 'Update';
        this.modal.formType = 'Update';
        this.selectedContentSubCategory = row.id;
        this.fetchContentSubCategory();
      }
      this.fetchContentCategories();
      this.modals.ContentSubCategoryModal = !this.modals.ContentSubCategoryModal;
    },

    fetchContentSubCategory() {
      let vm = this;
      let payload = {
        id: vm.selectedContentSubCategory,
        menuId: vm.menu.id,
      }

      vm.$store.dispatch('contentSubCategory/_APIShow', payload).then(response => {
        vm.contentSubCategory.id = response.data.data.id;
        vm.contentSubCategory.name = response.data.data.name;
        vm.contentSubCategory.status = response.data.data.status;
        vm.contentSubCategory.contentCategory = response.data.data[vm.menu.categoryForeignKey];
      }).catch(error => {
        console.error(error.response);
      });
    },

    fetchContentCategories() {
      let vm = this;
      vm.loaders.contentSubCategories = true
      let payLoad = {
        menuId: vm.menu.id,
      }
      vm.$store.dispatch('contentCategory/_APIGetAll', payLoad).then(response => {
        vm.response.contentCategories = response.data.data;
      }).catch(error => {
        console.error(error.response);
      }).finally(() => vm.loaders.contentSubCategories = false);
    },

    async onFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$refs.formContentSubCategory.$attrs.name === 'Save') {
            this.storeContentSubCategory();
          } else if (this.$refs.formContentSubCategory.$attrs.name === 'Update') {
            this.updateContentSubCategory();
          }
        } else {
          this.$notify.error({
            title: 'Validation Error',
            message: 'Please check values in the form'
          });
          return false;
        }
      });
    },

    storeContentSubCategory() {
      let vm = this;
      vm.loaders.contentSubCategories = true;
      let params = vm.contentSubCategory;
      params['menuId'] = vm.menu.id;

      vm.$store.dispatch('contentSubCategory/_APIStore', params).then(response => {
        vm.handleClose();
        vm.fetchContentSubCategories();
        vm.modals.ContentSubCategoryModal = false;
      }).catch(error => {
        console.log(error.response)
      }).finally(() => vm.loaders.contentSubCategories = false);
    },

    updateContentSubCategory() {
      let vm = this;
      vm.loaders.contentSubCategories = true;
      let params = vm.contentSubCategory;
      params['menuId'] = vm.menu.id;

      vm.$store.dispatch('contentSubCategory/_APIUpdate', params).then(response => {
        vm.handleClose();
        vm.fetchContentSubCategories();
        vm.modals.ContentSubCategoryModal = false;
      }).catch(error => {
        console.log(error.response)
      }).finally(() => vm.loaders.contentSubCategories = false);
    },

    handleClose() {
      this.clearContentCategoryForm();
      this.$refs.formContentSubCategory.resetFields();
      this.modals.ContentSubCategoryModal = false;
    },

    clearContentCategoryForm() {
      this.contentSubCategory.id = '';
      this.contentSubCategory.name = '';
      this.contentSubCategory.status = 1;
      this.contentSubCategory.contentCategory = '';

      this.modal.title = '';
      this.modal.buttonText = '';
      this.modal.formType = '';
      this.selectedContentSubCategory = '';
    },

    changePage(page) {
      this.fetchContentSubCategories();
    },
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
    menu() {
      let index = this.menus.findIndex(item => item.label === this.$route.params.menuTitle)
      return this.menus[index];
    },

  }
}
</script>

<style scoped>
.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>

